<template>
  <PageBase
    title="订单管理"
    :tabs="statusList"
    :tab="status"
    @tab-switch="onTabSwitch"
    @page-change="onPageChange"
    :dataList="dataList"
    :filters="filters"
    @search="searchChange"
    :page-num="pageNum"
    :total="total"
    :page-size="pageSize"
    @page-size-change="onPageSizeChange"
  >
    <OrderTable style="height: 100%;" :simple-filter="false" v-loading="loading.load" :data-list="dataList" @dataUpdate="loadData" show-type="prescription"></OrderTable>
  </PageBase>
</template>

<script>
import PageBase from '@/components/page/base'
import OrderTable from './detail/orderTable'

import {
  patientRequest,
  orderRequest
} from '@/api'

export default {
  components: { PageBase, OrderTable },
  data () {
    return {
      status: 'wait_pay',
      loading: {
        load: false
      },
      search: {},
      dataList: [],
      patientList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10
    }
  },
  computed: {
    patientFilters () {
      return this.patientList.map(v => {
        return {
          label: v.name,
          value: v.id
        }
      })
    },
    filters () {
      const patientFilters = this.patientFilters
      return [
        {
          type: 'select',
          show: true,
          label: '患者',
          field: 'patientId',
          filters: patientFilters
        }
      ]
    },
    statusList () {
      return [
        {
          value: 'wait_pay',
          label: '待付款',
          parm: {
            billStatus: 0
          }
        },
        {
          value: 'over',
          label: '已完成',
          parm: {
            billStatus: 1
          }
        },
        {
          value: 'is_refund',
          label: '已退款',
          parm: {
            haveBack: 1
          }
        }
      ]
    }
  },
  methods: {
    searchChange (data) {
      this.search = data
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    async loadData () {
      this.loading.load = true
      let parm = Object.assign({
        typeList: 'prescription,direct',
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, this.search)

      let status = this.statusList.find(v => v.value == this.status)
      parm = Object.assign(parm, (status ? status.parm : {}))
      
      const res = await orderRequest.get(parm)
      this.total = res.total

      let statusMap = {
        edit: '调整中',
        wait: '待审核',
        pass: '审核通过',
        down: '审核不通过'
      }
      res.data.forEach(v => {
        v.statusText = statusMap[v.status]
        v.makeType = v.type.includes('back') ? '退货' : (v.makeMainbodyId === v.buyMainbodyId ? '自主下单' : '代客下单')
      })

      this.dataList = res.data
      this.loading.load = false
    },
    onTabSwitch (value) {
      this.pageNum = 1;
      this.status = value
      this.loadData()
    },
    async loadPatient () {
      this.patientList = await patientRequest.get()
    }
  },
  mounted () {
    this.loadData()
    this.loadPatient()
  }
}
</script>